import React from "react"
import { navigate } from '@reach/router';
import CloseIcon from '../../assets/icons/close.svg';

const Category = ({label}) => (
  <div
    style={{
      border: '1px solid #707070',
      backgroundColor: 'rgba(112,112,112, 0.3)',
      padding: '0.25rem 1.5rem 0.25rem 1rem',
      height: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
    }}
  >
    <span>
      {label}
    </span>
    <CloseIcon style={{width: '15px', height: '15px', position: 'absolute', right:5, top: 2}}/>
  </div>
)
export default Category
